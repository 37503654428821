body {
  font-family: 'Poppins',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#paymaya-card-form {
  border-width: 0px;
  height: 50%;
  width: 80%;
  margin: auto;
  padding: auto;
}

.cn {
  background-color: #f7f7f7;
  height: 44px;
  border-radius: 30px;
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* iframe {
  display: block;
} */

.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.material-textfield {
  position: relative;
}

label {
  position: absolute;
  font-size: 1rem;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: gray;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: .1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}

input {
  font-size: 1rem;
  outline: none;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 1rem 0.7rem;
  color: gray;
  transition: 0.1s ease-out;
  width: 100%;
}

input:focus {
  border-color: rgb(10, 5, 16);
}

.expiration {
  border: 0px solid #bbb;
}

.expiration input {
  border: 1;
  text-align: center;
  justify-items: center;
  outline: 0;
  /* remove if you want input highlight */
  width: 45%
}

.containerBg {
  /* background: url('./assets/banner-bg.svg');
  background-repeat: no-repeat;
  background-size: cover; */
}

footer {
  margin-top: calc(5% + 150px);
  bottom: 0;
}

.paymentOptionLogo {
  height: 60px;
}

.backButton {
  text-decoration: none;
}

@media(max-width:960px) {

  .paymentOptionLogo {
    height: 60px;
    /* right: auto;
      bottom: 0; */
  }

  .backButton {
    text-align: center;
  }
}

.sticky {
  background-color: #f3f3f3 !important;
  position: relative;
  z-index: 10;
  left: 0;
  width: 100% !important;
  padding: 10px 24px 10px 24px
}